import React from "react";

import ProductImages from "../ProductImages";
import ProductSpecs from "../ProductSpecs";
import ProductForm from "./ProductForm";

const UpdateProductForm = ({
  getAllProducts,
  NotifyMessage,
  setOpenPopup,
  recordForEdit,
}) => {
  return (
    <>
      <ProductSpecs Id={recordForEdit.Id} />
      <ProductImages Id={recordForEdit.Id} />
      <div className="card">
        <ProductForm
          getAllProducts={getAllProducts}
          NotifyMessage={NotifyMessage}
          setOpenPopup={setOpenPopup}
          recordForEdit={recordForEdit}
        />
      </div>
    </>
  );
};

export default UpdateProductForm;
