import React from "react";
import "./topnav.css";
import Popover from "@material-ui/core/Popover";
import { signout } from "../../actions/auth";
import { useHistory } from "react-router-dom";
import { IconButton } from "@material-ui/core";

const Topnav = ({ handleDrawerToggle }) => {
  const history = useHistory();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  return (
    <div className="topnav">
      <IconButton className="topnav__MenuIcon" onClick={handleDrawerToggle}>
        <i className="bx bx-menu"></i>
      </IconButton>

      <div className="topnav__right">
        <div className="topnav__right-item">
          <div className="dropdown">
            <IconButton className="topnav__right-user" onClick={handleClick}>
              <i className="bx bx-user-circle"></i>
            </IconButton>

            <Popover
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            >
              <div className="topnav__right__dropdown__menu">
                <button
                  onClick={() =>
                    signout(() => {
                      history.replace("/signin");
                    })
                  }
                >
                  <span>Logout</span>
                  <i
                    className="bx bx-log-out-circle"
                    style={{ transform: "rotate(180deg)" }}
                  ></i>
                </button>
              </div>
            </Popover>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Topnav;
