import React from "react";
import { Button as MuiButton, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: { margin: theme.spacing(0.5) },
  label: {
    textTransform: "none",
  },
  primary: { 
    whiteSpace: "nowrap",
    backgroundColor: theme.palette.primary.main,
    "& .MuiButton-label": {
      color: theme.palette.primary.contrastText,
    },
    "&:hover": {
      "& .MuiButton-label": {
        color: theme.palette.primary.main,
      },
    },
  },
}));

export default function Button(props) {
  const { text, size, color, variant, onClick, ...other } = props;
  const classes = useStyles();
  const colour = color || "primary";
  return (
    <MuiButton
      variant={variant || "contained"}
      size={size || "large"}
      color={color || "primary"}
      onClick={onClick}
      {...other}
      className={`${classes[colour]}`}
      classes={{ root: classes.root, label: classes.label }}
    >
      {text}
    </MuiButton>
  );
}
