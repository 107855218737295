import fetch from "isomorphic-fetch";
import { useHistory } from "react-router-dom";
import { API } from "../config";

import { getCookie, userSessionExpired } from "./auth";

const PartnerActions = () => {
  const history = useHistory();
  const deleteService = (Id) => {
    return fetch(`${API}/api/partner/${Id}`, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        userSessionExpired(response, history);
        return response.json();
      })
      .catch((err) => err);
  };
  const viewServices = () => {
    return fetch(`${API}/api/partners/`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        return response.json();
      })
      .catch((err) => err);
  };
  const addNewService = (service) => {
    return fetch(`${API}/api/partner/`, {
      method: "POST",
      headers: {
        Accept: "application/json",
      },
      body: service,
    })
      .then((response) => {
        userSessionExpired(response, history);
        return response.json();
      })
      .catch((err) => err);
  };
  const updateService = (service, Id) => {
    return fetch(`${API}/api/partner/${Id}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
      },
      body: service,
    })
      .then((response) => {
        userSessionExpired(response, history);
        return response.json();
      })
      .catch((err) => err);
  };

  return {
    deleteService,
    viewServices,
    addNewService,
    updateService,
  };
};

export default PartnerActions;
