import fetch from "isomorphic-fetch";
import { useHistory } from "react-router-dom";
import { API } from "../config";

import { getCookie, userSessionExpired } from "./auth";
const ProductActions = () => {
  const history = useHistory();
  const deleteProduct = (Id) => {
    return fetch(`${API}/api/delete-product/${Id}`, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("token")}`,
      },
    })
      .then((response) => {
        userSessionExpired(response, history);
        return response.json();
      })
      .catch((err) => err);
  };
  const viewProducts = () => {
    return fetch(`${API}/api/get-all-products`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("token")}`,
      },
    })
      .then((response) => {
        return response.json();
      })
      .catch((err) => err);
  };
  const addProduct = (product) => {
    return fetch(`${API}/api/add-new-product`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${getCookie("token")}`,
      },
      body: product,
    })
      .then((response) => {
        userSessionExpired(response, history);
        return response.json();
      })
      .catch((err) => err);
  };
  const updateProduct = (product, Id) => {
    return fetch(`${API}/api/update-product/${Id}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("token")}`,
      },
      body: JSON.stringify(product),
    })
      .then((response) => {
        userSessionExpired(response, history);
        return response.json();
      })
      .catch((err) => err);
  };
  const addProductImage = (image, Id) => {
    return fetch(`${API}/api/add-product-image/${Id}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${getCookie("token")}`,
      },
      body: image,
    })
      .then((response) => {
        userSessionExpired(response, history);
        return response.json();
      })
      .catch((err) => err);
  };
  const deleteProductImage = (Id) => {
    return fetch(`${API}/api/delete-product-image/${Id}`, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("token")}`,
      },
    })
      .then((response) => {
        userSessionExpired(response, history);
        return response.json();
      })
      .catch((err) => err);
  };
  const viewAllProductImages = (Id) => {
    return fetch(`${API}/api/get-all-product-images/${Id}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        return response.json();
      })
      .catch((err) => err);
  };
  const viewAllProductSpecs = (Id) => {
    return fetch(`${API}/api/get-all-product-specs/${Id}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        return response.json();
      })
      .catch((err) => err);
  };
  const addNewSpec = (spec, Id) => {
    return fetch(`${API}/api/add-product-spec/${Id}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("token")}`,
      },
      body: JSON.stringify(spec),
    })
      .then((response) => {
        userSessionExpired(response, history);
        return response.json();
      })
      .catch((err) => err);
  };
  const updateSpec = (spec, Id) => {
    return fetch(`${API}/api/update-product-spec/${Id}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("token")}`,
      },
      body: JSON.stringify(spec),
    })
      .then((response) => {
        userSessionExpired(response, history);
        return response.json();
      })
      .catch((err) => err);
  };
  const deleteProductSpec = (Id) => {
    return fetch(`${API}/api/delete-product-specs/${Id}`, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("token")}`,
      },
    })
      .then((response) => {
        userSessionExpired(response, history);
        return response.json();
      })
      .catch((err) => err);
  };
  return {
    deleteProduct,
    viewProducts,
    addProduct,
    updateProduct,
    addProductImage,
    deleteProductImage,
    viewAllProductImages,
    deleteProductSpec,
    viewAllProductSpecs,
    addNewSpec,
    updateSpec,
  };
};

export default ProductActions;
