import React from "react";
import { CssBaseline, ThemeProvider, createTheme } from "@material-ui/core";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Dashboard from "./pages/admin/Dashboard";
import Signin from "./pages/Signin";
import Users from "./pages/admin/Users";
import Service from "./pages/admin/Service";
import Product from "./pages/admin/Product";
import Partner from "./pages/admin/Partner";
import Client from "./pages/admin/Client";
import ServiceProduct from "./pages/admin/ServiceProduct";
const theme = createTheme({
  palette: {
    primary: {
      main: "rgb(0,69,132)",
      light: "rgba(0,69,132, 0.8)",
      contrastText: "#fff",
    },
    secondary: {
      main: "#f83245",
      light: "#f8324526",
      contrastText: "#fff",
    },
    background: {
      default: "#f4f5fd",
    },
  },
  overrides: {
    MuiAppBar: {
      root: {
        shadow: "0",
      },
    },
  },
  props: {
    MuiIconButton: {
      disableRipple: true,
    },
  },
});

const App = () => {
  return (
    <Router>
      <ThemeProvider theme={theme}>
        <Switch>
          <Route exact path="/" component={Dashboard} />
          <Route exact path="/signin" component={Signin} />
          <Route exact path="/users" component={Users} />
          <Route exact path="/products" component={Product} />
          <Route exact path="/services" component={Service} />
          <Route exact path="/service/products" component={ServiceProduct} />
          <Route exact path="/partner" component={Partner} />
          <Route exact path="/client" component={Client} />
        </Switch>
        <CssBaseline />
      </ThemeProvider>
    </Router>
  );
};

export default App;
