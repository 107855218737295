import React from "react";
import ReactDOM from "react-dom";
import "./assets/boxicons-2.0.7/css/boxicons.min.css";
import "./assets/css/grid.css";
import "./assets/css/index.css";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import App from "./App";

ReactDOM.render(<App />, document.getElementById("root"));
reportWebVitals();
