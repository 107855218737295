import React from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import { Drawer } from "@material-ui/core";
import "./sidebar.css";
import logo from "../../assets/images/logo.png";
import SidebarItem from "./SidebarItem";

const drawerWidth = 270;

const sidebar_items = [
  {
    display_name: "Dashboard",
    route: "/",
    icon: "bx bxs-dashboard",
  },
  {
    display_name: "Users",
    route: "/users",
    icon: "bx bx-user-pin",
  },
  {
    display_name: "Products",
    route: "/products",
    icon: "bx bx-cart",
  },
  {
    display_name: "Services",
    route: "/services",
    icon: "bx bx-cog",
  },
  {
    display_name: "Service products",
    route: "/service/products",
    icon: "bx bx-cart",
  },
  {
    display_name: "Partners",
    route: "/partner",
    icon: "bx bxs-user-check",
  },
  {
    display_name: "Clients",
    route: "/client",
    icon: "bx bx-group",
  },
];

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },

  drawerPaper: {
    width: drawerWidth,
  },
  borderR: {
    borderRight: "none",
  },
}));

const Sidebar = (props) => {
  const classes = useStyles();

  const activeItem = sidebar_items.findIndex(
    (item) => item.route === props.location.pathname
  );

  return (
    <Drawer
      className={classes.drawer}
      variant="persistent"
      anchor="left"
      open={props.open}
      classes={{
        paperAnchorDockedLeft: classes.borderR,
        paper: classes.drawerPaper,
      }}
    >
      <div className="sidebar">
        <div className="sidebar__logo">
          <Link to="/">
            <img src={logo} alt="company logo" />
          </Link>
        </div>
        {sidebar_items.map((item, index) => (
          <Link to={item.route} key={index}>
            <SidebarItem
              title={item.display_name}
              icon={item.icon}
              key={index}
              active={index === activeItem}
            />
          </Link>
        ))}
      </div>
    </Drawer>
  );
};

export default Sidebar;
