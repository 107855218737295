import React from "react";
import { API } from "../config";
import Controls from "./controls/Controls";
import Loading from "./ui/Loading";
import { Fab, Tooltip } from "@material-ui/core";
import { BsTrash } from "react-icons/bs";
import Notification from "./ui/Notification";
import ConfirmDialog from "./ui/ConfirmDialog";
import Notify from "./ui/Notify";

import ServiceProductActions from "../actions/ServiceProductActions";

const ServiceProductImages = ({ Id }) => {
  const {
    addServiceProductImage,
    deleteServiceProductImage,
    viewAllServiceProductImages,
  } = ServiceProductActions();
  const { NotifyMessage, notify, setNotify } = Notify();
  const filePicker = React.useRef(null);

  const [values, setValues] = React.useState({
    loading: false,
    serviceProductsImage: [],
    reload: false,
  });
  const [confirmDialog, setConfirmDialog] = React.useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const { loading, serviceProductsImage } = values;
  React.useEffect(() => {
    getAllServiceProductImages();
  }, []);
  const getAllServiceProductImages = () => {
    setValues({ ...values, loading: true });
    viewAllServiceProductImages(Id).then((data) => {
      if (data.err) {
        NotifyMessage({
          message: data.err,
          type: "error",
        });
      } else {
        setValues({
          ...values,
          loading: false,
          serviceProductsImage: data.result,
        });
      }
    });
  };

  const handleFileChange = (e) => {
    const formData = new FormData();
    setValues({ ...values, submitting: true });
    formData.append("image", e.target.files[0]);
    addServiceProductImage(formData, Id).then((data) => {
      if (data.err) {
        setValues({ ...values, submitting: false });
        NotifyMessage({
          message: data.err,
          type: "error",
        });
      } else {
        NotifyMessage({
          message: data.message,
          type: "success",
        });
        getAllServiceProductImages();
      }
    });
  };
  const onDelete = (Id) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    deleteServiceProductImage(Id).then((data) => {
      if (data.err) {
        NotifyMessage({
          message: data.err,
          type: "error",
        });
      } else {
        NotifyMessage({
          message: data.message,
          type: "success",
        });
        getAllServiceProductImages();
      }
    });
  };
  return (
    <div className="card">
      <>
        <label>Service Product Images</label>
        <Controls.Button
          color="primary"
          onClick={() => {
            filePicker.current.click();
          }}
          variant="outlined"
          text="Upload image"
        />
        <input
          type="file"
          accept="image/*"
          ref={filePicker}
          hidden
          onChange={handleFileChange}
        />
      </>
      <div>
        {loading ? (
          <Loading />
        ) : (
          <div className="product__image__list">
            {serviceProductsImage.map((image) => {
              return (
                <div className="image__card">
                  <img
                    src={`${API}/${image.imageURI}`}
                    className="product__image"
                  />
                  <Tooltip title="Delete Ad">
                    <Fab
                      color="secondary"
                      className="image__delete__icon"
                      onClick={() => {
                        setConfirmDialog({
                          isOpen: true,
                          title: "Are you sure to delete this image?",
                          subTitle: "You can't undo this operation",
                          onConfirm: () => {
                            onDelete(image.Id);
                          },
                        });
                      }}
                    >
                      <BsTrash fontSize="large" />
                    </Fab>
                  </Tooltip>
                </div>
              );
            })}
          </div>
        )}
      </div>
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
      <Notification notify={notify} setNotify={setNotify} />
    </div>
  );
};

export default ServiceProductImages;
