import ServiceProductFeatures from "../ServiceProductFeatures";
import ServiceProductImages from "../ServiceProductImages";
import ServiceProductForm from "./ServiceProductForm";

const UpdateServiceProductForm = ({
  getAllServiceProduct,
  NotifyMessage,
  setOpenPopup,
  recordForEdit,
  services,
}) => {
  return (
    <>
      <ServiceProductFeatures Id={recordForEdit.Id} />
      <ServiceProductImages Id={recordForEdit.Id} />
      <div className="card">
        <label>Service Product information</label>
        <ServiceProductForm
          getAllServiceProduct={getAllServiceProduct}
          NotifyMessage={NotifyMessage}
          setOpenPopup={setOpenPopup}
          recordForEdit={recordForEdit}
          services={services}
        />
      </div>
    </>
  );
};

export default UpdateServiceProductForm;
