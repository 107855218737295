import React from "react";
import { API } from "../config";
import Controls from "./controls/Controls";
import Loading from "./ui/Loading";
import { Divider, Fab, Popover, Tooltip } from "@material-ui/core";
import { BsTrash } from "react-icons/bs";
import Notification from "./ui/Notification";
import ConfirmDialog from "./ui/ConfirmDialog";
import Notify from "./ui/Notify";
import ProductActions from "../actions/ProductActions";
import { Form, useForm } from "./ui/useForm";
const initialFValues = {
  detail: "",
  editing: false,
};
const ProductSpecs = ({ Id }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const [showSpecForm, setShowSpecForm] = React.useState(false);
  const [openPopup, setOpenPopup] = React.useState(false);
  const [specData, setSpec] = React.useState({});
  const [recordForEdit, setRecordForEdit] = React.useState(null);

  const { updateSpec, addNewSpec, viewAllProductSpecs, deleteProductSpec } =
    ProductActions();
  const { NotifyMessage, notify, setNotify } = Notify();

  const [value, setValue] = React.useState({
    loading: false,
    productSpecs: [],
  });
  const [confirmDialog, setConfirmDialog] = React.useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const { loading, productSpecs } = value;
  React.useEffect(() => {
    if (recordForEdit != null) {
      setValues({
        ...recordForEdit,
      });
    }
  }, [recordForEdit]);
  React.useEffect(() => {
    getAllProductSpecs();
  }, []);
  const getAllProductSpecs = () => {
    setValue({ ...value, loading: true });
    viewAllProductSpecs(Id).then((data) => {
      if (data.err) {
        NotifyMessage({
          message: data.err,
          type: "error",
        });
      } else {
        setValue({
          ...value,
          loading: false,
          productSpecs: data.result,
        });
      }
    });
  };
  const validate = (fieldValues = values) => {
    const temp = { ...errors };

    if ("detail" in fieldValues)
      temp.detail =
        fieldValues.detail.length != 0 ? "" : "This field is required.";

    setErrors({
      ...temp,
    });

    if (fieldValues == values) return Object.values(temp).every((x) => x == "");
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      if (values.editing == false) {
        addNewSpec({ ...values }, Id).then((data) => {
          if (data.err) {
            setValues({ ...values, submitting: false });
            NotifyMessage({
              message: data.err,
              type: "error",
            });
          } else {
            NotifyMessage({
              message: data.message,
              type: "success",
            });
            resetForm();
            getAllProductSpecs();
          }
        });
      } else {
        updateSpec({ detail: values.detail }, values.Id).then((data) => {
          if (data.err) {
            setValues({ ...values, submitting: false });
            NotifyMessage({
              message: data.err,
              type: "error",
            });
          } else {
            NotifyMessage({
              message: data.message,
              type: "success",
            });
            setShowSpecForm(false);
            resetForm();
            setRecordForEdit(null);
            setValues(initialFValues);
            getAllProductSpecs();
          }
        });
      }
    }
  };

  const SpecForm = () => {
    return (
      <Form onSubmit={handleSubmit}>
        <div style={{ display: "flex", alignItems: "flex-start" }}>
          {" "}
          <Controls.Input
            label="Specification"
            name="detail"
            value={values.detail}
            onChange={handleInputChange}
            error={errors.detail}
          />
          <Controls.Button
            color="primary"
            variant="outlined"
            disabled={values.submitting ? true : false}
            text={
              values.editing == true
                ? values.submitting
                  ? "Editing..."
                  : "Edit"
                : values.submitting
                ? "Adding..."
                : "Add"
            }
            className="Button"
            type="submit"
          />
          <Controls.Button
            color="default"
            text="cancle"
            onClick={() => {
              setShowSpecForm(false);
              setErrors({});
            }}
          />
        </div>
      </Form>
    );
  };
  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialFValues, true, validate);
  console.log(values);
  const onDelete = (Id) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    deleteProductSpec(Id).then((data) => {
      if (data.err) {
        NotifyMessage({
          message: data.err,
          type: "error",
        });
      } else {
        NotifyMessage({
          message: data.message,
          type: "success",
        });
        handleClose();
        getAllProductSpecs();
      }
    });
  };
  const openInPopup = (item) => {
    console.log(item);
    handleClose();
    setRecordForEdit({ ...item, editing: true });
    setShowSpecForm(true);
  };
  console.log(specData);
  return (
    <div className="card">
      {" "}
      <label>Product Specification</label>
      <div>
        {loading ? (
          <Loading />
        ) : (
          <>
            <div className="product__image__list">
              {productSpecs.map((spec, index) => {
                return (
                  <div key={index} className="spec__card">
                    {spec.detail}
                    <div>
                      {" "}
                      <i
                        className="bx bx-dots-vertical-rounded"
                        onClick={(event) => {
                          handleClick(event);
                          setSpec(spec);
                        }}
                      />{" "}
                      <Popover
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "center",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "center",
                        }}
                      >
                        <div className="spec__options">
                          <button onClick={() => onDelete(specData.Id)}>
                            <span>Remove specification</span>
                            <i className="bx bx-trash"></i>
                          </button>
                          <Divider />
                          <button
                            onClick={() => {
                              handleClose();
                              setRecordForEdit({ ...specData, editing: true });
                              setShowSpecForm(true);
                            }}
                          >
                            <span>Update specification</span>
                            <i className="bx bx-edit"></i>
                          </button>
                        </div>
                      </Popover>
                    </div>
                  </div>
                );
              })}
            </div>
            {showSpecForm && (
              <div className="card">
                {" "}
                <SpecForm />
              </div>
            )}
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                width: "80%",
              }}
            >
              <Controls.Button
                color="primary"
                onClick={() => {
                  setRecordForEdit(null);
                  setShowSpecForm(true);
                  setValues(initialFValues);
                }}
                variant="outlined"
                text="Add Specification"
                className="newButton"
              />
            </div>
          </>
        )}
      </div>{" "}
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
      <Notification notify={notify} setNotify={setNotify} />
    </div>
  );
};

export default ProductSpecs;
