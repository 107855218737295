import Controls from "../controls/Controls";
import { Form, useForm } from "../ui/useForm";
import { Grid, makeStyles } from "@material-ui/core";
import { useEffect, useRef, useState } from "react";
import ProductActions from "../../actions/ProductActions";

const initialFValues = {
  name: "",
  description: "",
  price: "",
  image: null,
  editing: false,
};
const useStyles = makeStyles((theme) => ({
  icon: {
    borderRadius: "50%",
    height: "40px",
    width: "40px",
  },
}));
const ProductForm = ({
  getAllProducts,
  NotifyMessage,
  setOpenPopup,
  recordForEdit,
}) => {
  const classes = useStyles();

  const { addProduct, updateProduct } = ProductActions();
  console.log(recordForEdit);
  useEffect(() => {
    if (recordForEdit != null) {
      setValues({
        ...recordForEdit,
      });
    }
  }, [recordForEdit]);
  const validate = (fieldValues = values) => {
    const temp = { ...errors };

    if ("name" in fieldValues)
      temp.name = fieldValues.name.length != 0 ? "" : "This field is required.";
    if (values.editing == false) {
      if ("image" in fieldValues)
        temp.image = fieldValues.image != null ? "" : "This field is required.";
    }
    if ("price" in fieldValues)
      temp.price =
        fieldValues.price.length != 0 ? "" : "This field is required.";
    // if ("price" in fieldValues)
    //   temp.price =
    //     parseInt(fieldValues.price) == 0 ? "Price must be greater than 0." : "";
    setErrors({
      ...temp,
    });

    if (fieldValues == values) return Object.values(temp).every((x) => x == "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialFValues, true, validate);
  console.log(values);
  const handleSubmit = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("name", values.name);
    formData.append("description", values.description);
    formData.append("price", values.price);

    formData.append(
      "image",
      values.image instanceof File ? values.image : null
    );
    if (validate()) {
      if (values.price == 0) {
        setErrors({ ...errors, price: "Price must be greater than 0." });
      } else {
        setValues({ ...values, submitting: true });
        if (values.editing == false) {
          addProduct(formData).then((data) => {
            if (data.err) {
              setValues({ ...values, submitting: false });
              NotifyMessage({
                message: data.err,
                type: "error",
              });
            } else {
              NotifyMessage({
                message: data.message,
                type: "success",
              });
              setOpenPopup(false);
              resetForm();
              getAllProducts();
            }
          });
        } else {
          updateProduct(
            {
              price: values.price,
              description: values.description,
              name: values.name,
            },
            values.Id
          ).then((data) => {
            if (data.err) {
              setValues({ ...values, submitting: false });
              NotifyMessage({
                message: data.err,
                type: "error",
              });
            } else {
              NotifyMessage({
                message: data.message,
                type: "success",
              });
              setOpenPopup(false);
              resetForm();
              getAllProducts();
            }
          });
        }
      }
    }
  };
  return (
    <Form onSubmit={handleSubmit}>
      <Grid container>
        <Grid item xs={12}>
          <label>Product Information</label>
        </Grid>
        <Grid item xs={6} className="grid-input">
          <Controls.Input
            label="Name"
            name="name"
            value={values.name}
            onChange={handleInputChange}
            error={errors.name}
          />
        </Grid>
        <Grid item xs={6} className="grid-input">
          <Controls.Input
            label="Description"
            name="description"
            multiline
            value={values.description}
            onChange={handleInputChange}
            error={errors.description}
          />
        </Grid>
        <Grid item xs={6} className="grid-input">
          <Controls.Input
            label="Price"
            name="price"
            value={values.price}
            onChange={handleInputChange}
            error={errors.price}
          />
        </Grid>{" "}
        {values.editing == false && (
          <Grid item xs={12}>
            <Grid item xs={6}>
              <label>Product Image</label>
              <Controls.Input
                name="image"
                type="file"
                onChange={handleInputChange}
                error={errors.image}
                autoFocus={true}
              />
            </Grid>
          </Grid>
        )}
      </Grid>
      <Grid item xs={12}>
        <Controls.Button
          color="primary"
          variant="outlined"
          disabled={values.submitting ? true : false}
          text={
            values.editing == true
              ? values.submitting
                ? "Editing..."
                : "Edit"
              : values.submitting
              ? "Adding..."
              : "Add"
          }
          className="Button"
          type="submit"
        />
      </Grid>
    </Form>
  );
};

export default ProductForm;
