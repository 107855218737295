import Controls from "../controls/Controls";
import { Form, useForm } from "../ui/useForm";
import { Grid, makeStyles } from "@material-ui/core";
import { useEffect, useRef, useState } from "react";

import { API } from "../../config";
import ServiceProductActions from "../../actions/ServiceProductActions";

const initialFValues = {
  name: "",
  description: "",
  url: "",
  image: null,
  serviceId: "",
  editing: false,
};
const useStyles = makeStyles((theme) => ({
  icon: {
    borderRadius: "50%",
    height: "40px",
    width: "40px",
  },
}));
const ServiceProductForm = ({
  getAllServiceProduct,
  NotifyMessage,
  setOpenPopup,
  recordForEdit,
  services,
}) => {
  const classes = useStyles();
  const { addServiceProduct, updateServiceProduct } = ServiceProductActions();
  const ImagePicker = useRef(null);
  const [file, setfile] = useState(null);
  useEffect(() => {
    if (recordForEdit != null) {
      setValues({
        ...recordForEdit,
      });
      setfile(recordForEdit.logoURI);
    }
  }, [recordForEdit]);
  const validate = (fieldValues = values) => {
    const temp = { ...errors };

    if ("name" in fieldValues)
      temp.name = fieldValues.name.length != 0 ? "" : "This field is required.";
    if (values.editing == false) {
      if ("image" in fieldValues)
        temp.image = fieldValues.image != null ? "" : "This field is required.";
    }

    if ("serviceId" in fieldValues)
      temp.serviceId =
        fieldValues.serviceId.length != 0 ? "" : "This field is required.";

    setErrors({
      ...temp,
    });

    if (fieldValues == values) return Object.values(temp).every((x) => x == "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialFValues, true, validate);
  const handleSubmit = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("name", values.name);
    formData.append("description", values.description);
    formData.append("url", values.url);

    formData.append(
      "image",
      values.image instanceof File ? values.image : null
    );
    if (validate()) {
      setValues({ ...values, submitting: true });
      if (values.editing == false) {
        addServiceProduct(formData, values.serviceId).then((data) => {
          if (data.err) {
            setValues({ ...values, submitting: false });
            NotifyMessage({
              message: data.err,
              type: "error",
            });
          } else {
            NotifyMessage({
              message: data.message,
              type: "success",
            });
            setOpenPopup(false);
            resetForm();
            getAllServiceProduct();
          }
        });
      } else {
        updateServiceProduct(formData, values.Id).then((data) => {
          if (data.err) {
            setValues({ ...values, submitting: false });
            NotifyMessage({
              message: data.err,
              type: "error",
            });
          } else {
            NotifyMessage({
              message: data.message,
              type: "success",
            });
            setOpenPopup(false);
            resetForm();
            getAllServiceProduct();
          }
        });
      }
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Grid container>
        <Grid item xs={6} className="grid-input">
          <Controls.Input
            label="Name"
            name="name"
            value={values.name}
            onChange={handleInputChange}
            error={errors.name}
          />
        </Grid>
        <Grid item xs={6} className="grid-input">
          <Controls.Input
            label="Description"
            name="description"
            multiline
            value={values.description}
            onChange={handleInputChange}
            error={errors.description}
          />
        </Grid>
        <Grid item xs={6} className="grid-input">
          <Controls.Input
            label="URL"
            name="url"
            value={values.url}
            onChange={handleInputChange}
            error={errors.url}
          />
        </Grid>
        <Grid item xs={6} className="grid-input">
          <Controls.Select
            name="serviceId"
            label="Services"
            value={values.serviceId}
            onChange={handleInputChange}
            options={services}
            error={errors.serviceId}
          />
        </Grid>

        <Grid item xs={12}>
          <Grid item xs={6}>
            <label>Service Product Image</label>
            {values.editing == true ? (
              <div className="flex space-x-3 items-center">
                {file && (
                  <img
                    className={classes.icon}
                    src={`${API}/${file}`}
                    alt={file}
                  />
                )}
                <Controls.Button
                  color="primary"
                  onClick={() => {
                    ImagePicker.current.click();
                  }}
                  variant="outlined"
                  text="Change Image"
                  className="newButton"
                />
                <input
                  label="Logo"
                  name="image"
                  type="file"
                  hidden
                  accept="image/*"
                  ref={ImagePicker}
                  onChange={handleInputChange}
                  autoFocus={true}
                />
              </div>
            ) : (
              <Controls.Input
                name="image"
                type="file"
                onChange={handleInputChange}
                error={errors.image}
              />
            )}{" "}
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Controls.Button
          color="primary"
          variant="outlined"
          disabled={values.submitting ? true : false}
          text={
            values.editing == true
              ? values.submitting
                ? "Editing..."
                : "Edit"
              : values.submitting
              ? "Adding..."
              : "Add"
          }
          className="Button"
          type="submit"
        />
      </Grid>
    </Form>
  );
};

export default ServiceProductForm;
