import Admin from "../../components/auth/Admin";
import Layout from "../../components/layout/Layout";
import ConfirmDialog from "../../components/ui/ConfirmDialog";
import Controls from "../../components/controls/Controls";
import Norecords from "../../components/ui/Norecords";
import Loading from "../../components/ui/Loading";
import {
  TableBody,
  TableRow,
  TableCell,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { Grid, Toolbar, InputAdornment } from "@material-ui/core";
import { useEffect, useState } from "react";
import { MdOutlineDelete } from "react-icons/md";
import { AiOutlineSearch } from "react-icons/ai";
import useTable from "../../components/ui/useTable";
import Notify from "../../components/ui/Notify";
import Popup from "../../components/ui/Popup";
import Notification from "../../components/ui/Notification";
import ClientActions from "../../actions/ClientAction";
import { IoMdAdd } from "react-icons/io";
import { FiEdit } from "react-icons/fi";
import ClientForm from "../../components/forms/ClientForm";
import { API } from "../../config";

const headCells = [
  { id: "imageUrl", label: "Logo" },
  { id: "name", label: "Name" },
  { id: "siteUrl", label: "Site URL" },
  { id: "actions", label: "Actions", disableSorting: true },
];
const useStyles = makeStyles((theme) => ({
  logo: {
    borderRadius: "50%",
    height: "40px",
    width: "40px",
  },
}));

const Client = () => {
  const classes = useStyles();
  const { NotifyMessage, notify, setNotify } = Notify();
  const { viewServices, deleteService } = ClientActions();
  const [recordForEdit, setRecordForEdit] = useState(null);
  const [openPopup, setOpenPopup] = useState(false);

  const [values, setValues] = useState({
    loading: false,
    products: [],
    reload: false,
  });
  const { loading, products, reload } = values;
  useEffect(() => {
    getAllServices();
  }, [reload]);

  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });

  const getAllServices = () => {
    setValues({ ...values, loading: true });
    viewServices().then((data) => {
      console.log(" View Clients ", data);
      if (data.err) {
        NotifyMessage({
          message: data.err,
          type: "error",
        });
      } else {
        setValues({
          ...values,
          products: data.result,
          loading: false,
        });
      }
    });
  };
  const onDelete = (Id) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    deleteService(Id).then((data) => {
      if (data.err) {
        NotifyMessage({
          message: data.err,
          type: "error",
        });
      } else {
        NotifyMessage({
          message: data.message,
          type: "success",
        });
        setValues({ ...values, reload: !reload });
      }
    });
  };
  const { TblContainer, TblHead, TblPagination, recordsAfterPagingAndSorting } =
    useTable(
      products.sort((a, b) =>
        a.createdAt < b.createdAt ? 1 : b.createdAt < a.createdAt ? -1 : 0
      ),
      headCells,
      filterFn
    );
  const handleSearch = (e) => {
    e.target.value = e.target.value.trimLeft();
    let target = e.target;
    setFilterFn({
      fn: (items) => {
        const columns = ["name", "siteUrl"];

        if (target.value == "") return items;
        else {
          return items.filter((x) => {
            return columns.some((column) => {
              return x[column]
                .toString()
                .toLowerCase()
                .includes(target.value.toLowerCase());
            });
          });
        }
      },
    });
  };
  const openInPopup = (item) => {
    setRecordForEdit({ ...item, editing: true });
    setOpenPopup(true);
  };
  return (
    <Layout>
      <Admin>
        <div className="card">
          <Typography variant="h6" className="page-header">
            Client
          </Typography>

          <Toolbar>
            <Controls.Input
              label="Search Client"
              className="searchInput"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AiOutlineSearch />
                  </InputAdornment>
                ),
              }}
              onChange={handleSearch}
            />{" "}
            <Controls.Button
              text="Add New"
              variant="outlined"
              startIcon={<IoMdAdd />}
              className="newButton"
              onClick={() => {
                setOpenPopup(true);
                setRecordForEdit(null);
              }}
            />
          </Toolbar>
          <Grid container>
            <Grid item xs={12} className="card__body">
              {loading ? (
                <Loading />
              ) : (
                <>
                  {" "}
                  <TblContainer>
                    <TblHead />
                    <TableBody>
                      {recordsAfterPagingAndSorting().length > 0 ? (
                        recordsAfterPagingAndSorting().map((item) => (
                          <TableRow key={item.Id}>
                            <TableCell>
                              <img
                                className={classes.logo}
                                src={`${API}/${item.imageUrl}`}
                                alt={item.imageUrl}
                              />
                            </TableCell>
                            <TableCell>{item.name}</TableCell>
                            <TableCell>{item.siteUrl}</TableCell>

                            <TableCell>
                              <Controls.ActionButton
                                color="primary"
                                title="Update"
                                onClick={() => {
                                  openInPopup(item);
                                }}
                              >
                                <FiEdit fontSize="medium" />
                              </Controls.ActionButton>
                              <Controls.ActionButton
                                color="secondary"
                                title="Delete"
                                onClick={() => {
                                  setConfirmDialog({
                                    isOpen: true,
                                    title:
                                      "Are you sure to delete this client?",
                                    subTitle: "You can't undo this operation",
                                    onConfirm: () => {
                                      onDelete(item.Id);
                                    },
                                  });
                                }}
                              >
                                <MdOutlineDelete fontSize="medium" />
                              </Controls.ActionButton>
                            </TableCell>
                          </TableRow>
                        ))
                      ) : (
                        <Norecords col={6} />
                      )}
                    </TableBody>
                  </TblContainer>
                  <TblPagination />
                </>
              )}
            </Grid>
          </Grid>

          <ConfirmDialog
            confirmDialog={confirmDialog}
            setConfirmDialog={setConfirmDialog}
          />
          <Notification notify={notify} setNotify={setNotify} />
        </div>
        <Popup
          title="Client Form"
          openPopup={openPopup}
          setOpenPopup={setOpenPopup}
        >
          <ClientForm
            getAllServices={getAllServices}
            NotifyMessage={NotifyMessage}
            setOpenPopup={setOpenPopup}
            recordForEdit={recordForEdit}
          />
        </Popup>
      </Admin>
    </Layout>
  );
};

export default Client;
