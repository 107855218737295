import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,makeStyles
} from "@material-ui/core";

import Slide from "@material-ui/core/Slide";
import IconButton from "@material-ui/core/IconButton";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});
const useStyles = makeStyles((theme) => ({
  dialogWrapper: {
    padding: "0px",
    position: "absolute",
    top: theme.spacing(1),
  },
  dialogTitle: {
    paddingRight: "0px",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

export default function Popup(props) {
  const { title, children, openPopup, setOpenPopup, maxWidth="md",scroll="paper" } = props;
  const classes = useStyles();

  return (
    <Dialog
      open={openPopup}
      TransitionComponent={Transition}
      maxWidth={maxWidth}
      // scroll={scroll}
      onClose={() => setOpenPopup(false)}
      classes={{ paper: classes.dialogWrapper }}
    >
      <DialogTitle className={classes.dialogTitle}>
        <div style={{ display: "flex" }}>
          <Typography variant="h6" component="div" style={{ flexGrow: 1 }}>
            {title}
          </Typography>
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={() => {
              setOpenPopup(false);
            }}
          >
            <i className="bx bx-x"></i>
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent dividers>{children}</DialogContent>
    </Dialog>
  );
}
