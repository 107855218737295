import fetch from "isomorphic-fetch";
import { useHistory } from "react-router-dom";
import { API } from "../config";
import { getCookie, userSessionExpired } from "./auth";

const ServiceProductActions = () => {
  const history = useHistory();
  const deleteServiceProduct = (Id) => {
    return fetch(`${API}/api/delete-service-product/${Id}`, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("token")}`,
      },
    })
      .then((response) => {
        userSessionExpired(response, history);
        return response.json();
      })
      .catch((err) => err);
  };
  const viewAllServiceProducts = () => {
    return fetch(`${API}/api/get-all-service-products`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("token")}`,
      },
    })
      .then((response) => {
        return response.json();
      })
      .catch((err) => err);
  };
  const addServiceProduct = (product, Id) => {
    return fetch(`${API}/api/add-new-service-product/${Id}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${getCookie("token")}`,
      },
      body: product,
    })
      .then((response) => {
        userSessionExpired(response, history);
        return response.json();
      })
      .catch((err) => err);
  };
  const updateServiceProduct = (product, Id) => {
    return fetch(`${API}/api/update-service-product/${Id}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${getCookie("token")}`,
      },
      body: product,
    })
      .then((response) => {
        userSessionExpired(response, history);
        return response.json();
      })
      .catch((err) => err);
  };

  const addServiceProductImage = (image, Id) => {
    return fetch(`${API}/api/add-service-product-image/${Id}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${getCookie("token")}`,
      },
      body: image,
    })
      .then((response) => {
        userSessionExpired(response, history);
        return response.json();
      })
      .catch((err) => err);
  };
  const deleteServiceProductImage = (Id) => {
    return fetch(`${API}/api/delete-service-product-image/${Id}`, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("token")}`,
      },
    })
      .then((response) => {
        userSessionExpired(response, history);
        return response.json();
      })
      .catch((err) => err);
  };
  const viewAllServiceProductImages = (Id) => {
    return fetch(`${API}/api/get-all-service-product-images/${Id}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        return response.json();
      })
      .catch((err) => err);
  };
  const viewAllServiceProductFeature = (Id) => {
    return fetch(`${API}/api/get-all-service-product-feature/${Id}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        return response.json();
      })
      .catch((err) => err);
  };
  const addNewFeature = (spec, Id) => {
    return fetch(`${API}/api/add-service-product-feature/${Id}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("token")}`,
      },
      body: JSON.stringify(spec),
    })
      .then((response) => {
        userSessionExpired(response, history);
        return response.json();
      })
      .catch((err) => err);
  };
  const updateFeature = (spec, Id) => {
    return fetch(`${API}/api/update-service-product-feature/${Id}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("token")}`,
      },
      body: JSON.stringify(spec),
    })
      .then((response) => {
        userSessionExpired(response, history);
        return response.json();
      })
      .catch((err) => err);
  };
  const deleteServiceProductFeature = (Id) => {
    return fetch(`${API}/api/delete-service-product-feature/${Id}`, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("token")}`,
      },
    })
      .then((response) => {
        userSessionExpired(response, history);
        return response.json();
      })
      .catch((err) => err);
  };
  return {
    deleteServiceProduct,
    viewAllServiceProducts,
    addServiceProduct,
    updateServiceProduct,
    viewAllServiceProductFeature,
    viewAllServiceProductImages,
    deleteServiceProductFeature,
    updateFeature,
    addNewFeature,
    deleteServiceProductImage,
    addServiceProductImage,
  };
};

export default ServiceProductActions;
