import Layout from "../../components/layout/Layout";
import Admin from "../../components/auth/Admin";
import React from "react";
const Dashboard = () => {
  const [companyNum, setCompanyNum] = React.useState(0);
  const [catagoryNum, setCatagoryNum] = React.useState(0);
  const [subCatagoryNum, setSubCatagoryNum] = React.useState(0);
  const [usersNum, setUsersNum] = React.useState(0);
  return (
    <Layout>
      <Admin>
        <div className="card">
          <div class="container">
            <div class="our-team">
              <div class="picture">
                <i className="bx bx-cart-alt picture" />
              </div>
              <div class="team-content">
                <h4 class="name">{companyNum}</h4>
                <h3 class="title">Products</h3>
              </div>
            </div>
            <div class="our-team">
              <div class="picture">
                <i className="bx bx-category picture" />
              </div>
              <div class="team-content">
                <h4 class="name">{catagoryNum}</h4>
                <h3 class="title">Services</h3>
              </div>
            </div>
            <div class="our-team">
              <div class="picture">
                <i className="bx bx-category picture" />
              </div>
              <div class="team-content">
                <h4 class="name">{subCatagoryNum}</h4>
                <h3 class="title">Sub Catagories</h3>
              </div>
            </div>
            <div class="our-team">
              <div class="picture">
                <i className="bx bx-user picture" />
              </div>
              <div class="team-content">
                <h4 class="name">{usersNum}</h4>
                <h3 class="title">Users</h3>
              </div>
            </div>
          </div>
        </div>
      </Admin>
    </Layout>
  );
};

export default Dashboard;
