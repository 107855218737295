import Controls from "../controls/Controls";
import { Form, useForm } from "../ui/useForm";
import { Grid, makeStyles } from "@material-ui/core";
import { useEffect, useRef, useState } from "react";
import ServiceActions from "../../actions/ServiceActions";
import { API } from "../../config";

const initialFValues = {
  name: "",
  description: "",

  image: null,
  icon: null,
  editing: false,
};
const useStyles = makeStyles((theme) => ({
  icon: {
    borderRadius: "50%",
    height: "150px",
    width: "150px",
    marginBottom: "10px",
  },
}));
const ServiceForm = ({
  getAllServices,
  NotifyMessage,
  setOpenPopup,
  recordForEdit,
}) => {
  const classes = useStyles();

  const { addNewService, updateService } = ServiceActions();
  const ImagePicker = useRef(null);
  const IconPicker = useRef(null);
  const [file, setfile] = useState(null);
  const [icon, setIcon] = useState(null);
  useEffect(() => {
    if (recordForEdit != null) {
      setValues({
        ...recordForEdit,
      });
      setfile(recordForEdit.imageURI);
      setIcon(recordForEdit.iconURI);
    }
  }, [recordForEdit]);
  const validate = (fieldValues = values) => {
    const temp = { ...errors };

    if ("name" in fieldValues)
      temp.name = fieldValues.name.length != 0 ? "" : "This field is required.";
    if (values.editing == false) {
      if ("image" in fieldValues)
        temp.image = fieldValues.image != null ? "" : "This field is required.";
    }
    if (values.editing == false) {
      if ("icon" in fieldValues)
        temp.icon = fieldValues.icon != null ? "" : "This field is required.";
    }

    setErrors({
      ...temp,
    });

    if (fieldValues == values) return Object.values(temp).every((x) => x == "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialFValues, true, validate);
  const handleSubmit = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("name", values.name);
    formData.append("description", values.description);
    formData.append(
      "image",
      values.image instanceof File ? values.image : null
    );
    formData.append("icon", values.icon instanceof File ? values.icon : null);
    if (validate()) {
      setValues({ ...values, submitting: true });
      if (values.editing == false) {
        addNewService(formData).then((data) => {
          if (data.err) {
            setValues({ ...values, submitting: false });
            NotifyMessage({
              message: data.err,
              type: "error",
            });
          } else {
            NotifyMessage({
              message: data.message,
              type: "success",
            });
            setOpenPopup(false);
            resetForm();
            getAllServices();
          }
        });
      } else {
        updateService(formData, values.Id).then((data) => {
          if (data.err) {
            setValues({ ...values, submitting: false });
            NotifyMessage({
              message: data.err,
              type: "error",
            });
          } else {
            NotifyMessage({
              message: data.message,
              type: "success",
            });
            setOpenPopup(false);
            resetForm();
            getAllServices();
          }
        });
      }
    }
  };
  console.log(values);
  return (
    <Form onSubmit={handleSubmit}>
      <Grid container>
        <Grid item xs={12}>
          <label>Service Information</label>
        </Grid>
        <Grid item xs={6} className="grid-input">
          <Controls.Input
            label="Name"
            name="name"
            value={values.name}
            onChange={handleInputChange}
            error={errors.name}
          />
        </Grid>
        <Grid item xs={6} className="grid-input">
          <Controls.Input
            label="Description"
            name="description"
            multiline
            value={values.description}
            onChange={handleInputChange}
            error={errors.description}
          />
        </Grid>

        <Grid item xs={6}>
          <label>Service image</label>
          {values.editing == true ? (
            <div className="flex space-x-3 items-center">
              {file && (
                <img
                  className={classes.icon}
                  src={`${API}/${file}`}
                  alt={file}
                />
              )}
              <Controls.Button
                color="primary"
                onClick={() => {
                  ImagePicker.current.click();
                }}
                variant="outlined"
                text="Change Image"
                className="newButton"
              />
              <input
                label="Logo"
                name="image"
                type="file"
                hidden
                accept="image/*"
                ref={ImagePicker}
                onChange={handleInputChange}
                autoFocus={true}
              />
            </div>
          ) : (
            <Controls.Input
              name="image"
              type="file"
              onChange={handleInputChange}
              error={errors.image}
            />
          )}
        </Grid>
        <Grid item xs={6}>
          {" "}
          <label>Service icon</label>
          {values.editing == true ? (
            <div className="flex space-x-3 items-center">
              {icon && (
                <img
                  className={classes.icon}
                  src={`${API}/${icon}`}
                  alt={icon}
                />
              )}
              <Controls.Button
                color="primary"
                onClick={() => {
                  IconPicker.current.click();
                }}
                variant="outlined"
                text="Change Icon"
                className="newButton"
              />
              <input
                label="Logo"
                name="icon"
                type="file"
                hidden
                accept="image/*"
                ref={IconPicker}
                onChange={handleInputChange}
                autoFocus={true}
              />
            </div>
          ) : (
            <Controls.Input
              name="icon"
              type="file"
              onChange={handleInputChange}
              error={errors.icon}
            />
          )}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Controls.Button
          color="primary"
          variant="outlined"
          disabled={values.submitting ? true : false}
          text={
            values.editing == true
              ? values.submitting
                ? "Editing..."
                : "Edit"
              : values.submitting
              ? "Adding..."
              : "Add"
          }
          className="Button"
          type="submit"
        />
      </Grid>
    </Form>
  );
};

export default ServiceForm;
