import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";

import { isAuth } from "../../actions/auth";

const Admin = (props) => {
  const { history, children } = props;
  useEffect(() => {
    if (!isAuth()) {
      history.replace(`/signin`);
    } 
  }, []);
  return <React.Fragment>{children}</React.Fragment>;
};
export default withRouter(Admin);
